import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ links }) => {
    return (
        <nav className={styles['Breadcrumbs']}>
            <div className={styles['Breadcrumbs__Container']}>
                <ol className={styles['Breadcrumbs__List']}>
                    {links.map((link, index) => (
                        <li
                            key={index}
                            className={styles['Breadcrumbs__Item']}
                        >
                            {(links.length - 1) !== index ? (
                                <>
                                    <a
                                        href={link.href}
                                        className={styles['Breadcrumbs__Link']}
                                    >
                                        {link.title}
                                    </a>
                                    <div className={styles['Breadcrumbs__Icon']}>
                                        <Icon type={'breadcrumbChevron'} />
                                    </div>
                                </>
                            ) : (
                                <span
                                    aria-current="page"
                                    className={
                                        classNames([
                                            styles['Breadcrumbs__Link'],
                                            styles['Breadcrumbs__Link--Active']]
                                        )}
                                >
                                    {link.title}
                                </span>
                            )}
                        </li>
                    ))}
                </ol>
            </div>
        </nav>
    );
};

Breadcrumbs.propTypes = {
    links: PropTypes.array
};

Breadcrumbs.defaultProps = {
    links: []
};

export default Breadcrumbs;
