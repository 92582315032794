import PropTypes from 'prop-types';
import styles from './StreamField.module.scss';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { snakeToPascal } from '../../utils/caseconverters';

const NewsCardList = dynamic(() => import('../NewsCardList'));
const Accordion = dynamic(() => import('../Accordion'));
const ThreeColumnModule = dynamic(() => import('../ThreeColumnModule'));
const YouTubeVideoList = dynamic(() => import('../YouTubeVideoList'));
const ArtistCarousel = dynamic(() => import('../ArtistCarousel'));
const DownloadLinkList = dynamic(() => import('../DownloadLinkList'));
const ProductImageCardList = dynamic(() => import('../ProductImageCardList'));
const ProductCardList = dynamic(() => import('../ProductCardList'));
const ProductHighlightItem = dynamic(() => import('../ProductHighlightItem'));
const ImageVideoCarousel = dynamic(() => import('../ImageVideoCarousel'));
const SoftwareCardList = dynamic(() => import('../SoftwareCardList'));
const PlayableAudioCardList = dynamic(() => import('../PlayableAudioCardList'));
const AudioPlayerList = dynamic(() => import('../AudioPlayerList'));
const TeaserBlock = dynamic(() => import('../TeaserBlock'));


const Components = {
    NewsCardList,
    Accordion,
    YouTubeVideoList,
    ArtistCarousel,
    DownloadLinkList,
    ProductImageCardList,
    ThreeColumnModule,
    ProductCardList,
    ProductHighlightItem,
    ImageVideoCarousel,
    SoftwareCardList,
    PlayableAudioCardList,
    AudioPlayerList,
    TeaserBlock,
};

const StreamField = ({ items, onLinkClick }) => {
    if (!items.length) {
        return null;
    }

    const dynamicComponents = items.map((item, index) => {
        if (item.component) {
            item.component = snakeToPascal(item.component);
        } else if (item.wysiwyg) {
            item.component = 'Wysiwyg';
        }

        const Component = Components[item.component];
        if (!Component) {
            return null;
        }

        const extraProps = {};
        
        if (item.component === 'Accordion') {
            extraProps.onLinkClick = onLinkClick;
        }

        return (
            <div
                key={index}
                id={item.id}
                className={classNames(
                    styles['StreamField__Component'],
                    styles[`StreamField__Component--${item.component}`]
                )}
            >
                <Component {...item} {...extraProps} />
            </div>
        );
    });

    return <div className="StreamField">{dynamicComponents}</div>;
};

StreamField.propTypes = {
    items: PropTypes.array,
    onLinkClicked: PropTypes.func,
};

StreamField.defaultProps = {
    items: [],
    onLinkClicked: null, // Set null as default to indicate no handler if not provided
};

export default StreamField;
