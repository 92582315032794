import PropTypes from 'prop-types';
import styles from './TabLinks.module.scss';
import classNames from 'classnames';
import React, { useState } from 'react';
import Link from 'next/link';
import StreamField from '../StreamField/StreamField';

const TabLinks = ({ tabs, content, onLinkClick }) => {
    const activeTabIndex = tabs?.findIndex((tab) => tab.isActive);


    return (
        <div id="tab-links" className={styles['TabLinks']}>
            <div className={styles['TabLinks__Container']}>
                <div
                    className={classNames(styles['TabLinks__TabWrapper'], {
                        [styles['TabLinks__TabWrapper--SpaceBetween']]:
                            tabs?.length >= 4,
                    })}>
                    {tabs?.map((tab, index) => (
                        <div
                            key={index}
                            className={classNames(styles['TabLinks__Tab'], {
                                [styles['TabLinks__Tab--Active']]:
                                    index === activeTabIndex,
                            })}>
                            <Link href={tab.link} scroll={false}>
                                {tab.name}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles['TabLinks__Content']}>
                <StreamField items={content} onLinkClick={onLinkClick}/>
            </div>
        </div>
    );
};

TabLinks.propTypes = {
    tabs: PropTypes.array,
    content: PropTypes.array,
};

export default TabLinks;
