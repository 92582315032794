import React, { useState } from 'react';
import styles from './ProductHero.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageLightBox from '../ImageLightBox/ImageLightBox';
import Image from '../Image';

const ProductHero = ({ modelImage, text, tabs, showCase, title }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    return (
        <div className={styles['ProductHero']}>
            <div className={styles['ProductHero__Container']}>
                <div className={styles['ProductHero__Row']}>
                    <div className={styles['ProductHero__Col']}>
                        {modelImage && (
                            <div
                                className={
                                    styles['ProductHero__ImageWrapper']
                                }>
                                <h1 className="sr-only">{title}</h1>
                                <Image
                                    {...modelImage}
                                    layout="fill"
                                    objectFit="contain"
                                    priority={true}
                                />
                            </div>
                        )}
                        {!modelImage && (
                            <div>
                                <div
                                    className={
                                        styles['ProductHero__TitleWrapper']
                                    }>
                                    <h1
                                        className={
                                            styles['ProductHero__Title']
                                        }>
                                        {title}
                                    </h1>
                                </div>
                            </div>
                        )}
                        <p className={styles['ProductHero__Text']}>{text}</p>
                    </div>
                    {tabs && tabs.length > 0 && (
                        <div
                            className={classNames(styles['ProductHero__Col'], {
                                [styles['ProductHero__Col__Single']]:
                                    tabs.length === 1,
                            })}>
                            {tabs.length > 1 && (
                                <p className={styles['ProductHero__ModelText']}>
                                    Models
                                </p>
                            )}

                            <div
                                className={styles['ProductHero__TabContainer']}>
                                {tabs.map((tab, index) => (
                                    <button
                                        key={index}
                                        className={classNames(
                                            styles['ProductHero__Tab'],
                                            {
                                                [styles[
                                                    'ProductHero__Tab--Active'
                                                ]]: index === activeTab,
                                            },
                                            {
                                                [styles[
                                                    'ProductHero__Tab--Single'
                                                ]]: tabs.length === 1,
                                            }
                                        )}
                                        onClick={() => handleTabClick(index)}>
                                        {tab.text}
                                    </button>
                                ))}
                            </div>

                            <div className="ProductHero__TabContent">
                                {tabs[activeTab] && (
                                    <>
                                        <div
                                            className={
                                                styles[
                                                'ProductHero__TabImageWrapper'
                                                ]
                                            }>
                                            <ImageLightBox
                                                image={tabs[activeTab].image}
                                                src={tabs[activeTab].image.url}
                                                objectFit="contain"
                                            />
                                        </div>
                                        <p
                                            className={
                                                styles['ProductHero__InfoText']
                                            }>
                                            {tabs[activeTab].infoText}
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {showCase && (
                    <div className={styles['ProductHero__RowBottom']}>
                        {showCase.map((item, index) => (
                            <>
                                {item.image && (
                                    <div
                                        key={index}
                                        className={styles['ProductHero__ImageWrapper']}>
                                        <Image
                                            {...item.image}
                                            layout="fill"
                                            objectFit="contain"
                                            priority={true}
                                        />
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

ProductHero.propTypes = {
    modelImage: PropTypes.object,
    text: PropTypes.string,
    modelText: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            image: PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired,
            }).isRequired,
            infoText: PropTypes.string.isRequired,
        })
    ),
    showCase: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired,
            }).isRequired,
        })
    ),
};

ProductHero.defaultProps = {
    text: '',
    modelText: '',
    tabs: [],
    showCase: [],
};

export default ProductHero;
