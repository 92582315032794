// import PropTypes from 'prop-types';
import styles from './ImageLightBox.module.scss';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import { useState, useRef } from 'react';

import Image from '../Image/Image';
import Icon from '../Icon/Icon';

const ImageLightBox = ({ image, objectFit }) => {
    const [open, setOpen] = useState(false);

    const renderNext = false;
    const renderPrev = false;
    const finite = true;

    const maxZoomPixelRatio = 3;
    const fullscreenRef = useRef(null);
    const zoomRef = useRef(null);

    const imageSrcSet = image && {
        src: image.url,
        width: image.width,
        height: image.height,
    };

    const controller = {
        closeOnBackdropClick: true,
    }

    return (
        <div className={styles['ImageLightBox']} onClick={() => setOpen(true)}>
            <div className={styles['ImageLightBox__IconContainer']}>
                <Icon type="magnifyingGlassWhite" size={'xlarge'} />
            </div>
            <Image
                {...image}
                fill={true}
                objectFit={objectFit}
            />
            <Lightbox
                className={styles['ImageLightBox__LightBox']}
                fullscreen={{ ref: fullscreenRef }}
                on={{
                    click: () => fullscreenRef.current?.enter(),
                    click: () => zoomRef.current?.zoomIn(),
                }}
                controller={controller}
                zoom={{ maxZoomPixelRatio }}
                open={open}
                close={() => setOpen(false)}
                slides={[imageSrcSet]}
                plugins={[Zoom, Fullscreen]}
                carousel={{ finite }}
                render={{
                    buttonPrev: renderPrev ? undefined : () => null,
                    buttonNext: renderNext ? undefined : () => null,
                }}
            />
        </div>
    );
};

ImageLightBox.propTypes = {};

ImageLightBox.defaultProps = {
    image: {
        focal: {}
    }
};

export default ImageLightBox;
