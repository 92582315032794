import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useRouter } from 'next/router'
import styles from './ProductPage.module.scss';
import Breadcrumbs from '../../components/Breadcrumbs';

//Components
import { basePageWrap } from '../BasePage';
import Header from '../../components/Header';
import ProductHero from '../../components/ProductHero/ProductHero';
import Footer from '../../components/Footer';
import TabLinks from '../../components/TabLinks/TabLinks';
import ProductCardList from '../../components/ProductCardList';
import { scroller } from "react-scroll";

const ProductPage = ({
    settings,
    hero,
    tabs,
    breadcrumbs,
    content,
    accessories,
}) => {
    const router = useRouter()

    useEffect(() => {
        const shouldScrolLDownToTabLinks = router.query && router.query.scrollToTabs === "1";

        if (shouldScrolLDownToTabLinks) {
            scroller.scrollTo(`tab-links`, {
                duration: 500,
                delay: 250,
                smooth: "ease",
                offset: -16
            })
        }
    }, []);

    const trackLinkClick = (event) => {
        if (typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push({
                event: 'downloads_download',
                product_name: hero.title,
                file_name: event.target.innerText
            });
        }
    }

    return (
        <div className={styles['ProductPage']}>
            <Header {...settings.header} />
            <div className={styles['ProductPage__Container']}>
                <main>
                    <div className={styles['ProductPage__Breadcrumbs']}>
                        <Breadcrumbs {...breadcrumbs} />
                    </div>
                    {hero && <ProductHero {...hero} />}
                    {tabs && <TabLinks tabs={tabs} content={content} onLinkClick={trackLinkClick}/>}
                    {accessories && (
                        <ProductCardList
                            products={accessories}
                            type="accessory"
                            isHighlighted={false}
                        />
                    )}
                </main>
            </div>
            <Footer {...settings.footer} />
        </div>
    );
};

ProductPage.propTypes = {
    hero: PropTypes.object,
    footer: PropTypes.object,
    TabLinks: PropTypes.array,
    breadcrumbs: PropTypes.object,
};

ProductPage.defaultProps = {
    settings: {
        hero: {},
        footer: {},
    },
    hero: {},
    tabLinks: { tabs: [] },
    breadcrumbs: {
        links: [],
    },
};

export default basePageWrap(ProductPage);
