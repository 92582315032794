// import PropTypes from 'prop-types';
import styles from './Image.module.scss';
import React, { useState } from 'react';
import classNames from 'classnames';
import NextImage from 'next/image';
import LazyLoad from 'react-lazy-load';

const Image = (props) => {
    return props.priority ? (
        <ImageElement {...props} />
    ) : (
        <LazyLoad>
            <ImageElement {...props} />
        </LazyLoad>
    );
};

const ImageElement = ({
    focal,
    sizes = '',
    url,
    title,
    alt,
    priority = false,
    quality = null,
    style,
    objectFit,
}) => {
    const [hasLoadedImage, setHasLoadedImage] = useState(false);
    const imageClasses = classNames(styles['Image'], {
        [styles['Image--Loaded']]: hasLoadedImage,
    });

    return (
        <NextImage
            className={imageClasses}
            src={url}
            fill
            style={{
                ...style,
                objectFit: objectFit ? objectFit : 'cover',
                objectPosition: `${focal.x} ${focal.y}`,
            }}
            priority={priority}
            sizes={sizes}
            quality={quality}
            onLoadingComplete={() => setHasLoadedImage(true)}
            alt={alt || title || ''}
        />
    );
};

Image.propTypes = {};

Image.defaultProps = {
    focal: {},
};

export default Image;
